class browser {

    static isChrome() {

        const ua = navigator.userAgent;
        const isChrome = ua.indexOf("Chrome") !== -1;
        const isEdge = ua.indexOf("Edge") !== -1;

        return isChrome && !isEdge;
    }
}

export default browser;