export const setBrowserInfo = () => {
    navigator.browserInfo = (() => {
        const ua = navigator.userAgent;
        let tem;
        let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
        if (/trident/i.test(M[1])) {
            tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
            return { browser: "Internet Explorer", version: tem[1] || "" };
        }
        if (M[1] === "Chrome") {
            tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
            if (tem !== null) {
                return { browser: tem[1], version: tem[2] };
            }
        }

        M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];

        tem = ua.match(/version\/(\d+)/i);
        if (tem !== null) M.splice(1, 1, tem[1]);
        return { browser: M[0], version: M[1] };
    })();
};

export const isInternetExplorer = () => {
    const ua = window.navigator.userAgent;

    const msie = ua.indexOf("MSIE ");
    if (msie > 0) {
        // IE 10 or older => return version number
        return true;
    }

    const trident = ua.indexOf("Trident/");
    if (trident > 0) {
        return true;
    }

    // other browser
    return false;
};

export const isIOS = () => {
    return !!navigator.platform && /mac|ipad|iphone/.test(navigator.platform.toLowerCase());
};

export const getQueryParams = () => {
    let match;
    const pl = /\+/g;
    const search = /([^&=]+)=?([^&]*)/g;
    const decode = (s) => {
        return decodeURIComponent(s.replace(pl, " "));
    };
    const query = window.location.search.substring(1);

    const urlParams = {};
    while ((match = search.exec(query))) {
        urlParams[decode(match[1])] = decode(match[2]);
    }
    return urlParams;
};
export const getQueryParam = (parameter) => {
    if (parameter === undefined) {
        return undefined;
    }
    const parameters = getQueryParams();
    for (let key in parameters) {
        if (key.toUpperCase() === parameter.toUpperCase()) {
            return parameters[key];
        }
    }
};
