export const misEnumerations = {

    ResultChannelTypes: {
        TIMING: 0,
        GAME: 1,
        JUDGEMENT: 2
    },
    SortingType: {
        BY_RANK: [0, "asc"],
        BY_POS: [1, "asc"]
    },
    AppearanceModes: {
        Default: 0,
        Contrast: 1,
        Dark: 2
    },
    CompetitionStatus: {
        SCHEDULED: "2",
        COMING_UP: "50",
        RUNNING: "4",
        UNOFFICIAL: "6",
        OFFICIAL: "7",
        OFFICIAL_8: "8"
    },
    BinariesCategories: {
        PHOTO_FINISH: "PHOTOFINISH",
        COURSE: "COURSE",
        ATHLETE: "ATHLETE"
    },

    ViewTypes: {
        GRID_VIEW: 0,
        LIST_VIEW: 1
    },
    Environments: {
        DEV: "DEV",
        TEST: "TEST",
        PROD: "PROD",
        DEMO: "DEMO"
    },
    MedalUnitTypes: {
        GOLD: "G",
        BRONZE: "B"
    }
};

export const CommonCompTypes = {
    TEAM: "TEAM",
    INDIVIDUAL: "INDIVIDUAL",
    SINGLES: "SINGLES",
    DOUBLES: "DOUBLES"
};
