import { getQueryParams } from "../libs/jetuniversalcomponents/utils_es6/JetUC.browserInfo.js";
import { misEnumerations } from "./mis.enumerations.js";

export const QUERY_NAMES = {
    SPORT: "sport",
    NAME_SPACE: "namespace",
    APP: "app",
    LANGUAGE: "lng",
    PROFILE: "profile",
    SEASON: "season",
    EVENT: "event",
    MODE: "mode", // Allowed values: ENVIRONMENT_TYPES
    TYPE: "type"
};

export const MIS_TYPES = {
    WWMIS: "WWCIS",
    VENUE: "Venue"
};

export const flagPaths = {
    DEFAULT: "general/img/flags/",
    LARGE: "general/img/flags/large/"
};

export const imgPaths = {
    sport: "general/img/logos/sport/",
    timekeeper: "general/img/logos/timekeeper/",
    weather: "general/img/weather/",
    wind20px: "general/img/weather/wind/20px/",
    wind26px: "general/img/weather/wind/26px/",
    general: "general/img/general/",
    athletePlaceHolder: "general/img/general/athlete_undefined.png"
};

export const getMISLocalConfig = () => window.CIS_LocalConfig;

export const getCurrentEventId = () => CIS.EVENT_ID;

export const getSeasonCode = () => CIS.SeasonCode;

export const getSportCode = () => CIS.Sportcode;

export const getSportVideoPath = sport => `sports/${sport}/videos/`;

export const getMISRootElement = () => CIS.ROOT_ELEMENT;

export const getMISNameSpace = () => CIS.ApplicationName;

export const getEventConfigChannel = () => CIS.statics.channels.EventConfig;

export const getMISInstance = () => CIS;

export const getSportInstance = () => CIS.Sport;

export const getBaseChannels = () => CIS.statics.channels;

export const getSportConditions = () => CIS.Sport.Conditions;

export const getSportFeatures = () => CIS.Sport.Conditions.SportFeatures;

export const getMainNavConditions = () => CIS.Sport.Conditions.CustomNavigation.Buttons;

export const getEventConfigValues = () => CIS.BackEndConfig;

export const isInMobileMode = () => CIS.IS_MOBILE;

export const isVenueMIS = () => getMISLocalConfig().Type === MIS_TYPES.VENUE;

export const getAuthenticationId = () => {
    // Derive authentication id from path. e.g. mis/sjp/men/index.html, mis/swm/isl/
    // This corresponds with the pushserver paths.json, whose key should match the relative path name configured
    const paths = location.pathname.split("/").filter(x => x !== "entryPage");
    return paths.slice(1, paths.length - 1).join("_").toUpperCase();
};
export const getMISTypeAndEnvironment = () => {
    const queryParams = getQueryParams();
    const queryType = queryParams[QUERY_NAMES.TYPE];
    const queryMode = queryParams[QUERY_NAMES.MODE];
    let environment;
    if (queryMode) {
        environment = queryMode;
    } else {
        const isDev = location.href.contains("dev");
        const isDemo = location.href.contains("demo");
        const isUat = location.host.contains("uat") || location.href.contains("cis_test");
        if (isDev) {
            environment = misEnumerations.Environments.DEV;
        } else if (isDemo) {
            environment = misEnumerations.Environments.DEMO;
        } else if (isUat) {
            environment = misEnumerations.Environments.TEST;
        } else {
            environment = misEnumerations.Environments.PROD;
        }
    }

    const misType = queryType ?? ((
        location.pathname.contains("cis/index.html") ||
        location.pathname.contains("cis/index.debug.html") ||
        location.pathname.contains("cis/entryPage/index.html") ||
        location.pathname.contains("cis_test/entryPage/index.debug.html") ||
        location.pathname.contains("cis/entryPage/") ||
        location.pathname.contains("cis_test/entryPage/") ||
        location.pathname.endsWith("cis/") ||
        location.pathname.contains("cis_test/index.html") ||
        location.pathname.contains("cis_test/index.debug.html") ||
        location.pathname.endsWith("cis_test/") ||
        environment === misEnumerations.Environments.DEV) ? MIS_TYPES.VENUE : MIS_TYPES.WWMIS
    );

    return { misType, environment };
};

export const getMISLoadBalancedWebSocketHost = env => {
    switch (env) {
        case misEnumerations.Environments.DEV:
            return "ps-sync.origin.web.dev-sports.swisstiming.com";
        case misEnumerations.Environments.TEST:
        case misEnumerations.Environments.DEMO:
            return "ps-sync.origin.web.uat-sports.swisstiming.com";
        case misEnumerations.Environments.PROD:
            return "ps-sync.origin.web.swisstiming.com";
        default:
            return undefined;
    }
};

export const getMISCachingClusterHost = env => {
    switch (env) {
        case misEnumerations.Environments.DEV:
            return "https://ps-cache.web.dev-sports.swisstiming.com/node/db/";
        case misEnumerations.Environments.TEST:
        case misEnumerations.Environments.DEMO:
            return "https://ps-cache.web.uat-sports.swisstiming.com/node/db/";
        case misEnumerations.Environments.PROD:
            return "https://ps-cache.web.swisstiming.com/node/db/";
        default:
            return undefined;
    }
};

export const getPushServerClientOptions = (isWWMIS, environment) => {
    if (isWWMIS) {
        return {
            useCurrentHost: false,
            host: getMISLoadBalancedWebSocketHost(environment),
            cachingClusterURL: getMISCachingClusterHost(environment)
        };
    } else {
        return {
            useCurrentHost: true
        };
    }
};

export const getRunTimeConfig = () => CIS.RunTimeConfig;
